<template>
  <div v-if="commonCodeByOrder && gameCount"><!-- class="mainBg" -->
    <div class="main_iv">
      <div class="main_ment">
         <transition name="fade">
           <ui-swiper />
         </transition>
      </div>
      <div class="l_img" >
         <img src="@/assets/img/right.gif" style="position:absolute;z-index:-1;">
         <img src="@/assets/img/leftt.png">
      </div>
      <div class="w_img" >
         <img src="@/assets/img/right.gif" style="position:absolute;z-index:-1;">
         <img src="@/assets/img/sloat.png">
      </div>
      <div class="bg">
         <img src="@/assets/img/main_bg.gif">
      </div>
    </div>
    <div class="tabWrap">
       <div class="tab_01">
         <ul class="">
            <li class="" data-tab="tab-1" v-if="gameCount['casino']" @click="currentTab = 'tab-1'" :class="{'current': currentTab == 'tab-1'}">
               <img src="@/assets/img/on_tap01.png" />
               <h3>{{ $t('front.gnb.livecasino') }}<span>{{ $t('front.gnb.livecasino') }}</span></h3>
            </li>
            <li class="" data-tab="tab-1" v-if="gameCount['hc-casino']" @click="currentTab = 'tab-2'" :class="{'current': currentTab == 'tab-2'}">
               <img src="@/assets/img/on_tap02.png" />
               <h3>{{ $t('front.gnb.hotelcasino') }}<span>{{ $t('front.gnb.hotelcasino') }}</span></h3>
            </li>
            <li class="" data-tab="tab-2" v-if="gameCount['sports']" @click="currentTab = 'tab-4'" :class="{'current': currentTab == 'tab-4'}">
               <img src="@/assets/img/on_tap04.png" />
               <h3>{{ $t('front.gnb.sport') }}<span>{{ $t('front.gnb.sport') }}</span></h3>
            </li>
            <li class="" data-tab="tab-2" v-if="gameCount['slot']" @click="currentTab = 'tab-3'" :class="{'current': currentTab == 'tab-3'}">
               <img src="@/assets/img/on_tap03.png" />
               <h3>{{ $t('front.gnb.slotgame') }}<span>{{ $t('front.gnb.slotgame') }}</span></h3>
            </li>
            <li class="" data-tab="tab-2" v-if="gameCount['h-slot']" @click="currentTab = 'tab-6'" :class="{'current': currentTab == 'tab-6'}">
               <img src="@/assets/img/on_tap03.png" />
               <h3>{{ $t('front.gnb.hotelslot') }}<span>{{ $t('front.gnb.hotelslot') }}</span></h3>
            </li>
            <li class="" data-tab="tab-2" v-if="gameCount['minigame']" @click="currentTab = 'tab-5'" :class="{'current': currentTab == 'tab-5'}">
               <img src="@/assets/img/on_tap03.png" />
               <h3>{{ $t('front.gnb.minigame') }}<span>{{ $t('front.gnb.minigame') }}</span></h3>
            </li>
         </ul>
       </div>
    </div>
    <section class="mt51">
      <div id="tab-1" :key="'tab-1'" class="tab-content" v-if="currentTab == 'tab-1'">
        <ul class="betGames betGamesa">
          <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
            <li :style="getBackgroundImage(item)"
                v-if="item.groupCodeName.indexOf('HC-') == -1"
                @click="onCasinoSelectGame(item.groupCode, item.code)"
            >
              <div class="mask">
                 <div>
                    <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                    <!--a>
                       {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}} ▸
                    </a-->
                 </div>
              </div>
              <p class="name">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                <em :class="item.groupCode">{{ $t('front.gnb.livecasino') }}</em>
              </p>
              <!--span class="rblmark">{{ $t('front.main.new') }}</span-->
            </li>
          </template>
        </ul>
      </div><!-- tab-1 -->

      <div id="tab-2" :key="'tab-2'" class="tab-content" v-if="currentTab == 'tab-2'">
        <ul class="betGames betGamesc">
          <template v-for="item in commonCodeByOrder['casino']" v-bind:key="item.vendorKey">
            <li :style="getBackgroundImage(item)"
                v-if="item.groupCodeName.indexOf('HC-') > -1"
                @click="onCasinoSelectGame(item.groupCode, item.code)"
            >
              <div class="mask">
                 <div>
                    <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                    <!--a>
                       {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}} ▸
                    </a-->
                 </div>
               </div>
              <p class="name">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                <em :class="item.groupCode">{{ $t('front.gnb.hotelcasino') }}</em>
              </p>
              <!--span class="rblmark">{{ $t('front.main.new') }}</span-->
            </li>
          </template>
        </ul>
      </div><!-- tab-2 -->

      <div id="tab-3" :key="'tab-3'" class="tab-content" v-if="currentTab == 'tab-3'">
        <ul class="betGames betGamesb">
          <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
            <li :style="getBackgroundImage(item)"
                v-if="item.groupCodeName.indexOf('H-') === -1"
                @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"
            >
            <div class="mask">
               <div>
                  <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                  <!--a>
                     {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}} ▸
                  </a-->
               </div>
             </div>
              <p class="name">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                <em :class="item.groupCode">{{ $t('front.gnb.slotgame') }}</em>
              </p>
            </li>
          </template>
        </ul>
      </div><!-- tab-3 -->

      <div id="tab-6" :key="'tab-6'" class="tab-content" v-if="currentTab == 'tab-6'">
        <ul class="betGames betGamesa">
          <template v-for="item in commonCodeByOrder['slot']" v-bind:key="item.vendorKey">
            <li :style="getBackgroundImage(item)"
                v-if="item.groupCodeName.indexOf('H-') > -1"
                @click="slotOpen('isSlot', 'slot', item.codeName, item.code)"
            >
            <div class="mask">
               <div>
                  <em class="glogo" :style="getBackgroundLogoImage(item)"></em>
                  <!--a>
                     {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}} ▸
                  </a-->
               </div>
             </div>
              <p class="name">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                <em :class="item.groupCode">{{ $t('front.gnb.hotelslot') }}</em>
              </p>
              <!--span class="rblmark">{{ $t('front.main.new') }}</span-->
            </li>
          </template>
        </ul>
      </div><!-- tab-2 -->

      <div id="tab-4" :key="'tab-4'" class="tab-content" v-if="currentTab == 'tab-4'">
        <ul class="betGames betGamesa">
          <template v-for="item in commonCodeByOrder['sport']" v-bind:key="item.vendorKey">
            <li :style="getBackgroundImage(item)"
                @click="onCasinoSelectGame(item.groupCode, item.code)"
                @mouseover="item.isHover = true"
                @mouseout="item.isHover = false"
            >
              <p class="name">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                <em :class="item.groupCode">{{ $t('front.gnb.sport') }}</em>
              </p>
              <!--span class="rblmark">{{ $t('front.main.new') }}</span-->
            </li>
          </template>

        </ul>
      </div><!-- tab-4 -->

      <div id="tab-5" :key="'tab-5'" class="tab-content" v-if="currentTab == 'tab-5'">
        <ul class="betGames betGamesa">

          <template v-for="item in commonCodeByOrder['minigame']" v-bind:key="item.vendorKey">
            <li :style="getBackgroundImage(item)"
                @click="onCasinoSelectGame(item.groupCode, item.code)"
                @mouseover="item.isHover = true"
                @mouseout="item.isHover = false"
            >
              <p class="name">
                {{$i18n.locale !== 'ko' ? item[`codeName${$i18n.locale.toUpperCase()}`] : item.codeName}}
                <em :class="item.groupCode">{{ $t('front.gnb.minigame') }}</em>
              </p>
              <!--span class="rblmark">{{ $t('front.main.new') }}</span-->
            </li>
          </template>
        </ul>
      </div><!-- tab-5 -->

    </section>

    <div class="cs_Area">
      <!--h2 class="mainnames">{{ $t('front.common.cscenter') }}</h2-->
      <div class="servicecenter">
        <ul>
          <li>
             <h4>{{ $t('front.common.notice') }}</h4>
             <a>+ MORE</a>
          </li>
          <template v-for="item in noticeList" :key="item">
            <li @click="onClickNoticeRead(item)">
              <span>{{item.title}}</span>
              <span>{{item.regDate}}</span>
            </li>
          </template>
          <template v-for="item in noticeListMaxCount - noticeList.length" :key="item">
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
            </li>
          </template>
        </ul>
        <!-- <ul @click="goPageByName('')">
           <li>
             <h4>{{ $t('front.main.livecharge') }}</h4>
             <a>+ MORE</a>
          </li>
          <template v-for="item in cashInList" :key="item.memId">
            <li>
              <span>{{item.memId}}</span>
              <span><em>{{thousand(Number(item.cashAmt))}} 원</em>{{item.regDate}}</span>
            </li>
          </template>
          <template v-for="item in listMaxCount - cashInList.length" :key="item">
            <li>
              <span>&nbsp;</span>
              <span><em>&nbsp;</em>&nbsp;</span>
            </li>
          </template>
        </ul>
        <ul @click="goPageByName('')">
          <li>
             <h4>{{ $t('front.main.liveExchange') }}</h4>
             <a>+ MORE</a>
          </li>
          <template v-for="item in cashOutList" :key="item.memId">
            <li>
              <span>{{item.memId}}</span>
              <span><em>{{thousand(Number(item.cashAmt*-1))}} 원</em>{{item.regDate}}</span>
            </li>
          </template>
          <template v-for="item in listMaxCount - cashOutList.length" :key="item">
            <li>
              <span>&nbsp;</span>
              <span><em>&nbsp;</em>&nbsp;</span>
            </li>
          </template>
        </ul> -->
      </div>

      <ul class="guidewrap">
         <li><a @click="goPageByName('qnaList')"><img src="@/assets/img/cus_01.png" /><span>문의하기</span></a></li>
         <li><a><img src="@/assets/img/cus_02.png" /><span>게임가이드</span></a></li>
         <li><a @click="goPageByName('faq')"><img src="@/assets/img/cus_03.png" /><span>자주하는 질문</span></a></li>
         <li><a><img src="@/assets/img/cus_04.png" /><span>24시간 상담센터</span></a></li>
      </ul>
    </div>
    <div class="clogowrap">
       <ul class="calogo">
         <li><img src="@/assets/img/ca_logo1.png" /></li>
         <li><img src="@/assets/img/ca_logo2.png" /></li>
         <li><img src="@/assets/img/ca_logo3.png" /></li>
         <li><img src="@/assets/img/ca_logo4.png" /></li>
         <li><img src="@/assets/img/ca_logo5.png" /></li>
         <li><img src="@/assets/img/ca_logo6.png" /></li>
         <li><img src="@/assets/img/ca_logo7.png" /></li>
         <li><img src="@/assets/img/ca_logo8.png" /></li>
         <li><img src="@/assets/img/ca_logo10.png" /></li>
       </ul>
    </div>
    <div class="mainPopWrap">
      <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
        <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
          <div class="closeWrap">
            <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
            <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
          </div>
          <img :src="item.bannerImg" />
        </div>
      </template>
    </div>
  </div>

   <!--div class="loginmodal">
      <div class="mloading-container">
         <div class="mloading"></div>
         <div class="mloading-text">loading</div>
      </div>
   </div-->
</template>

<script>

import '@/styles/common.css'
import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'

import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { mapState } from 'vuex'

import store from '@/store'
import UiSwiper from '@/components/ui/UiSwiper.vue'
import { thousand } from '../libs/utils'

export default {
  name: 'Index.vue',
  components: {
    UiSwiper
  },
  created () {
    // this.emitter.emit('Loading', true)
    // this.emitter.emit('Loading', true)
    // setTimeout(() => {
    //
    // }, 5000)
    // setTimeout(() => {
    //   this.emitter.emit('Loading', false)
    // }, 8000)
    this.loadMain()
    this.popupMerge()
    this.loadNotice()
    this.loadCashStatus()
  },
  data () {
    return {
      currentTab: 'tab-1',
      popupList: {},
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      listMaxCount: 5,
      noticeListMaxCount: 5
    }
  },
  watch: {
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'popup',
      'popupLogin',
      'banner',
      'gameCount'
    ])

  },
  methods: {
    onClickNoticeRead (item) {
      console.log(item)
      this.$router.push({ name: 'noticeRead', params: { boardIdx: item.boardIdx } })
    },
    thousand,
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList.slice(0, this.listMaxCount)
          this.cashOutList = result.data.cashOutList.slice(0, this.listMaxCount)
        }
      })
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result)
          this.noticeList = result.data.boardList.slice(0, this.noticeListMaxCount)
        }
      })
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/game_main_pc_' + item.code + '_off.png')
        const imageOff = require('../assets/img/game_main_pc_' + item.code + '_off.png')
        if (image && imageOff) {
          if (item.isHover) {
            return { backgroundImage: 'url(' + image + ')' }
          } else {
            return { backgroundImage: 'url(' + imageOff + ')' }
          }
        } else {
          return { backgroundImage: 'url()' }
        }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    getBackgroundLogoImage (item) {
      try {
        const image = require('../assets/img/glogo' + item.code + '.png')
        return { backgroundImage: 'url(' + image + ')' }
      } catch (e) {
        return { backgroundImage: 'url()' }
      }
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = item
          }
        }
      })
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    },
    slotOpen (event, groupCode, codeName, code) {
      this.emitter.emit(event, { groupCode, codeName, code })
      this.emitter.emit('Loading', false)
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    }
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    // if (result) {
    next()
    // }
  }
}
</script>
<style scoped>
.mt111 {height: 400px}
.loading {z-index: 10;position: absolute;top: 0;width: 100%;height: 310px;background: #;padding-top: 40px;display: flex;align-items: center;justify-content: center;}
.loading img {height: 100%;}
.gameKinds {width: 1314px;margin: 0 auto;display: flex;gap: 18px;padding: 38px 0 0;}
.gameKinds li {width: 204px;height: 255px;border-radius: 10px;position: relative;}
.gameKinds .sexyz {
  background-image: url("~@/assets/img/gamesexy.png"), linear-gradient(to bottom, #fe807c, #e1423c);
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: auto;}
.gameKinds .pragz {
  background-image: url("~@/assets/img/gameprag.png"), linear-gradient(to bottom, #ffc182, #fc6e1b);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto;}
.gameKinds .hearth {
  background-image: url("~@/assets/img/gamehearth.png"), linear-gradient(to bottom, #bcde91, #238220);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto;}
.gameKinds .net {
  background-image: url("~@/assets/img/gamenet.png"), linear-gradient(to bottom, #8fafe5, #031f60 96%, #0b245d);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto;}
.popmark {position: absolute;top: 11px;left: 11px;width: 46px;height: 20px;font-size: 12px;color: #fff;border-radius: 10px;background-color: #d73027;align-items: center;display: flex;justify-content: center;}
.newmark {position: absolute;top: 11px;left: 11px;width: 46px;height: 20px;font-size: 12px;color: #fff;border-radius: 10px;background-color: #4575b4;align-items: center;display: flex;justify-content: center;}
.rrdmark {position: absolute;top: 12px;right: 19px;width: 58px;height: 24px;font-size: 14px;color: #fff;border-radius: 12px;background-color: #d73027;align-items: center;display: flex;justify-content: center;}
.rblmark {position: absolute;top: 12px;right: 19px;width: 58px;height: 24px;font-size: 14px;color: #fff;border-radius: 12px;background-color: #4575b4;align-items: center;display: flex;justify-content: center;}
.gameKinds li h4 {margin: 52px 0 0 20px;font-size: 16px;font-weight: bold;color: #fff;}
.gameKinds li span {margin: 4px 0 0 20px;font-size: 12px;color: #fff;}
h2.mainnames {width: 1314px;margin: 0 auto;padding: 30px 0 20px;font-size: 30px;font-weight: bold;color: #000;}

.betGames .name {position: absolute;left: 0;bottom: 0;font-size: 16px;width: 100%;height: 40px;background: linear-gradient( to bottom, #14257a, #177bba);color: #fff;display: flex;align-items: center;justify-content: center;}
.betGames .name em {display: block;margin-top: 14px;font-size: 13px;font-weight: normal;background: none;display: none;}
/*.betGamesb .name {font-size: 16px;text-align: right;right: 8px;left: auto;top: 25px;transform: translate(0, 0);width: 100px;}
.betGamesb .name em {color: #f4d03b;font-size: 12px;margin-top: 5px;}*/
.name em.casino {color: #dc4a42;}
.name em.slot {color: #f4d03b;}
.name em.hotel {color: #fc7c30;}
.name em.sport {color: #244694;}
.name em.mini {color: #693290;}
li:hover .name, li:hover .name em {color: #fff;}

ul.tabs {display: flex;justify-content: center;align-items: center;padding: 30px 0;width: 1314px;margin: 0 auto;gap: 5px;}
ul.tabs li {display: flex;align-items: center;justify-content: center;font-size: 14px;color: #fff;cursor: pointer;width: 148px;height: 30px;border: solid 1px #777;background: #111;}
.tab-content{margin: 20px auto 0;}
.tab-content.current{display: inherit;}
ul.tabs li:hover {border: 1px solid #e5972d;background: #333;}
ul.tabs li.current {color: #000;border: 1px solid #e5972d;background: #e5972d;}
/*ul.tabs li:nth-child(1).current, ul.tabs li:nth-child(1):hover {background-color: #d73027;border-color: #d73027;color: #fff;}
ul.tabs li:nth-child(2).current, ul.tabs li:nth-child(2):hover {background-color: #fc7625;border-color: #fc7625;color: #fff;}
ul.tabs li:nth-child(3).current, ul.tabs li:nth-child(3):hover {background-color: #f4d03b;border-color: #f4d03b;color: #fff;}
ul.tabs li:nth-child(4).current, ul.tabs li:nth-child(4):hover {background-color: #2f8a29;border-color: #2f8a29;color: #fff;}
ul.tabs li:nth-child(5).current, ul.tabs li:nth-child(5):hover {background-color: #244694;border-color: #244694;color: #fff;}
ul.tabs li:nth-child(6).current, ul.tabs li:nth-child(6):hover {background-color: #693290;border-color: #693290;color: #fff;}*/

.slotmodalwrap::-webkit-scrollbar {width: 10px;}
.slotmodalwrap::-webkit-scrollbar-thumb {background-color: none;border-radius: 10px;background-clip: padding-box;border: 2px solid transparent;}
.slotmodalwrap::-webkit-scrollbar-track {background-color: none;border-radius: 10px;}
.slotmodal {background: #ededed;border: 1px solid #707070;border-radius: 30px;position: absolute;left: 50%;top: 50%; transform: translate(-50%,-50%);z-index: 1;width: 1314px;}
.slotmodal>h3 {width: 100%;position: relative;text-align: center;font-size: 30px;font-weight: bold;color: #fff;padding: 15px 0;background: #222;border-radius: 30px 30px 0 0;}
.slotmodal>h3::after {content: '';display: block;width: 100%;height: 17px;background-image: linear-gradient(to right, #319e8a, #1c594e);position: absolute;bottom: -25px;}
.slotmodal>h3 .close {position: absolute;right: 15px;top: 9px;height: 40px;}
.slotmodalwrap {max-height: 900px;overflow-y: auto;padding-top: 55px;}
.slotmodalwrap ul {padding: 0 65px 30px;display: flex;gap: 25px;justify-content: center;}
.slotmodalwrap ul li {transition: all ease 1s;cursor: pointer;}
.slotmodalwrap ul li:hover, .slotmodal ul li:focus, .slotmodal ul li:active {transform: scale(1.1);}
.slotmodalwrap ul li img {width: 100%;border-radius: 12px;border: 2px solid #319e8a;height: 200px;}

.mainPopWrap {display: flex;align-items: center;gap: 50px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 100;}
.mainPop {position: relative;min-width: 320px;background: #fff;border: 1px solid #109eef;z-index: 100;}
.mainPop .closeWrap {width: calc(100% - 30px);display: flex;align-items: center;justify-content: end;gap: 15px;position: absolute;bottom: 0;padding: 15px;background: rgba(0,0,0,.7);color: #fff;font-size: 12px;}
.mainPop a.close {color: #fff;}
.mainPop>img {width: 430px;height: 640px;vertical-align: bottom;object-fit: cover;object-position: center;}

</style>
<style>
.mainSlide img{width:100%;}
.mainSlide .swiper-slide>a{width:100%;display:block;}
.mainSlide .swiper-slide>a img{display:block;width:100%;height: 245px;object-fit:cover;object-position:center;}
.swiper-button-next, .swiper-button-prev {bottom: 50px;top: auto;}
.swiper-button-next {
  background-image: url(../assets/img/slideright.png) !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  right: 50%;
  bottom: 3px;
  margin-right: -640px;
  z-index: 11;
}
.swiper-button-prev {
  background-image: url(../assets/img/slideleft.png) !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  left: 50%;
  bottom: 3px;
  margin-left: -640px;
  z-index: 11;
}
.swiper-button-next:after, .swiper-button-prev:after {content: '';}
.swiper-button-next:hover, .swiper-button-prev:hover {opacity: 1;}
.swiper-pagination {bottom: 0 !important;gap: 100px;background-color: ;height: 50px;display: flex;justify-content: center;align-items: center;}
.swiper-pagination-bullet {width: 105px; min-height: 19px; background: transparent; opacity: 0.3; color: #fff;}
.swiper-pagination-bullet-active {border-radius: 0;opacity: 1;}
</style>
<style scoped src="@/styles/header.css"></style>
<style scoped src="@/styles/footer.css"></style>
<style scoped src="@/styles/main.css"></style>
